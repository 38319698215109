import React from 'react';
import PDFViewer from 'pdf-viewer-reactjs';

const link =
  'https://fh.filgolf.com/files/ele/D_Summary_of_Acts_of_the_Board_of_Directors.pdf';

export default function D() {
  return (
    <>
      <h5 className="is-size-5 mb-4">
        <strong>D. Summary of Acts of the Board of Directors</strong>
      </h5>
      <a className="button  is-link" href={link} download>
        DOWNLOAD
      </a>
      <PDFViewer
        document={{
          url: link
        }}
        rotationAngle={0}
        css="customViewer"
      />
    </>
  );
}
