import React from 'react';
import PDFViewer from 'pdf-viewer-reactjs';

const link =
  'https://fh.filgolf.com/files/ele/K_Secretarys_Certificate_on_Qualification_of_Independent_Directors.pdf';

export default function K() {
  return (
    <>
      <h5 className="is-size-5 mb-4">
        <strong>
          K. Secretarys Certificate on Qualification of Independent Directors
        </strong>
      </h5>
      <a className="button  is-link" href={link} download>
        DOWNLOAD
      </a>
      <PDFViewer
        document={{
          url: link
        }}
        rotationAngle={0}
        css="customViewer"
      />
    </>
  );
}
