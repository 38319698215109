import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Main from './components/layout/main';
import Header from './components/layout/header';
import Footer from './components/layout/footer';

import AboutUs from './components/pages/aboutus.js';
import ContactUs from './components/pages/contactus.js';
import DownloadableFiles from './components/pages/files.js';
import E00 from './components/pages/election/00.js';
import A from './components/pages/election/A.js';
import B from './components/pages/election/B.js';
import C1 from './components/pages/election/C1.js';
import C2 from './components/pages/election/C2.js';
import C3 from './components/pages/election/C3.js';
import C4 from './components/pages/election/C4.js';
import C5 from './components/pages/election/C5.js';
import C6 from './components/pages/election/C6.js';
import C7 from './components/pages/election/C7.js';
import C8 from './components/pages/election/C8.js';
import C9 from './components/pages/election/C9.js';
import D from './components/pages/election/D.js';
import E from './components/pages/election/E.js';
import F from './components/pages/election/F.js';
import G from './components/pages/election/G.js';
import H from './components/pages/election/H.js';
import I from './components/pages/election/I.js';
import J from './components/pages/election/J.js';
import K from './components/pages/election/K.js';
import L from './components/pages/election/L.js';

import './App.sass';
import './App.css';

function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <Main>
          <Switch>
            <Route exact path="/">
              <AboutUs />
            </Route>
            <Route path="/contactus">
              <ContactUs />
            </Route>
            <Route path="/d-files">
              <DownloadableFiles />
            </Route>
            <Route path="/ele/00">
              <E00 />
            </Route>
            <Route path="/ele/a">
              <A />
            </Route>
            <Route path="/ele/b">
              <B />
            </Route>
            <Route path="/ele/c1">
              <C1 />
            </Route>
            <Route path="/ele/c2">
              <C2 />
            </Route>
            <Route path="/ele/c3">
              <C3 />
            </Route>
            <Route path="/ele/c4">
              <C4 />
            </Route>
            <Route path="/ele/c5">
              <C5 />
            </Route>
            <Route path="/ele/c6">
              <C6 />
            </Route>
            <Route path="/ele/c7">
              <C7 />
            </Route>
            <Route path="/ele/c8">
              <C8 />
            </Route>
            <Route path="/ele/c9">
              <C9 />
            </Route>
            <Route path="/ele/d">
              <D />
            </Route>
            <Route path="/ele/e">
              <E />
            </Route>
            <Route path="/ele/f">
              <F />
            </Route>
            <Route path="/ele/g">
              <G />
            </Route>
            <Route path="/ele/h">
              <H />
            </Route>
            <Route path="/ele/i">
              <I />
            </Route>
            <Route path="/ele/j">
              <J />
            </Route>
            <Route path="/ele/k">
              <K />
            </Route>
            <Route path="/ele/l">
              <L />
            </Route>
          </Switch>
        </Main>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
