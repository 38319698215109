import React from 'react';
import { Link } from 'react-router-dom';

export default function files() {
  return (
    <div>
      <>
        <h5 className="is-size-5 mb-4">
          <strong>FILES</strong>
        </h5>
        <table>
          <tr>
            <td>
              <Link to="/ele/00">
                0. Forest Hills Golf and Country Club Inc. Definitive
                Information Statement 2May2024
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/a">A. Draft_Mngt. Report_2May2024 FIN</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/b">B. Notice of Annual Meeting</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/c1">
                C.1. Audited Financial Statements as of 31 Decemeber 2015
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/c2">
                C.2. Audited Financial Statements as of 31 December 2016
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/c3">
                C.3. Audited Financial Statements as of 31 December 2017
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/c4">
                C.4. Audited Financial Statements as of 31 December 2018
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/c5">
                C.5. Audited Financial Statements as of 31 December 2019
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/c6">
                C.6. Audited Financial Statements as of 31 December 2020
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/c7">
                C.7. Audited Financial Statements as of 31 December 2021
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/c8">
                C.8. Audited Financial Statements as of 31 December 2022
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/c9">
                C.9. Audited Financial Statements as of 31 December 2023
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/d">
                D. Summary of Acts of the Board of Directors
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/e">E. Minutes of 26 June 2015 Annual Meeting</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/f">F. Proxy Form</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/g">G. Sample Ballot</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/h">H. Requirements and Procedure for Voting</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/i">I. Certification of Independent Director</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/j">J. DIS Certification</Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/k">
                K. Secretary's Certificate on Qualification of Independent
                Directors
              </Link>
            </td>
          </tr>
          <tr>
            <td>
              <Link to="/ele/l">L. IFS_SEC FORM 17-Q_02MAY2024</Link>
            </td>
          </tr>
        </table>
      </>
    </div>
  );
}
