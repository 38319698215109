import { useState } from "react";
import { Link } from "react-router-dom";

import Logo from "../../asset/fh-logo-.jpg";

export default function Header() {
  const [activeNav, setActiveNav] = useState(false);

  return (
    <>
      <div className="is-flex is-flex-direction-column is-justify-content-center is-align-items-center">
        <figure className="image logo">
          <img
            src={Logo}
            width="112"
            height="28"
            alt="Foresthills Golf and Country Club, Inc."
          />
        </figure>
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="navbar-brand">
            <div
              role="button"
              className={
                activeNav ? "navbar-burger is-active" : "navbar-burger"
              }
              aria-label="menu"
              aria-expanded="false"
              data-target="main-nav-bar"
              onClick={() => setActiveNav(!activeNav)}
            >
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
              <span aria-hidden="true"></span>
            </div>
          </div>
          <div
            id="main-nav-bar"
            className={activeNav ? "navbar-menu is-active" : "navbar-menu"}
          >
            <div className="navbar-start">
              <Link to="/" className="navbar-item has-text-success">
                ABOUT US
              </Link>
              <Link to="/contactus" className="navbar-item has-text-success">
                CONTACT US
              </Link>
              <Link to="/d-files" className="navbar-item has-text-success">
                FILES
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}
