import { useEffect } from "react";

export default function ContactUs() {
  useEffect(() => {
    document.title = "Forest Hills Golf and Country Club | Contact us";
  }, []);
  return (
    <>
      <h5 className="is-size-5 mb-4">
        <strong>Contact us</strong>
      </h5>
      <h6 className="is-size-6">
        <strong className="has-text-success">Location</strong>
      </h6>
      <p className="mb-3">
        From Ortigas, Cubao and Quezon City, Forest hills can be accessed thru
        Marcos Highway all the way up to Barangay Inarawan. Turn left after the
        Petron Gasoline Station and follow the 30 meters wide Forest Hills
        Avenue till you reach the Club house.
      </p>
      <h6 className="is-size-6">
        <strong className="has-text-success">
          Contact Information of the Club
        </strong>
      </h6>
      <ul>
        <li>
          Trunk line - <strong>(02)994-7685 Loc. 11 & 12</strong>
        </li>
        <li>
          Front Desk/Reception - <strong>Loc. 13</strong>
        </li>
        <li>
          Office of the General Manager - <strong>Loc. 14</strong>
        </li>
        <li>
          Engineering - <strong>Loc. 15 & 22</strong>
        </li>
        <li>
          HRD - <strong>Loc. 17</strong>
        </li>
        <li>
          Membership & Handicapping - <strong>Loc. 18 & 20</strong>
        </li>
        <li>
          Billing Section - <strong>Loc. 19 & 21</strong>
        </li>
        <li>
          AR Section - <strong>Loc. 23</strong>
        </li>
        <li>
          Clinic - <strong>Loc. 24</strong>
        </li>
        <li>
          Security - <strong>Loc. 24</strong>
        </li>
        <li>
          Locker Section | <i>Admin. Office (DL)</i> -{" "}
          <strong>(02)401-2528</strong>
        </li>
        <li>
          Mobile Nos. - <strong> Globe (0915) 328-5319</strong>
        </li>
        <li>
          <i>Front Desk/Reception</i> - <strong> Smart (0918) 274-4679</strong>
        </li>
        <li>
          <i>Front Desk/Reception</i> - <strong> Sun (0933) 351-1226</strong>
        </li>
        <li>
          <i>Front Desk/Reception</i> - <strong> Globe (0927) 644-5194</strong>
        </li>
        <li>
          <i>Finance (Billing Section)</i> -{" "}
          <strong>
            <a href="mailto:foresthillsgolf@gmail.com">
              foresthillsgolf@gmail.com
            </a>
          </strong>
        </li>
        <li>
          <i>GM's Office</i> -{" "}
          <strong>
            <a href="mailto:foresthillsgolf.gm@gmail.com">
              foresthillsgolf.gm@gmail.com
            </a>
          </strong>
        </li>
      </ul>
    </>
  );
}
