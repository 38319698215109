export default function footer() {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          <strong className="has-text-success">
            Forest Hills Golf and Country Club
          </strong>
        </p>
      </div>
    </footer>
  );
}
