import React from 'react';
import PDFViewer from 'pdf-viewer-reactjs';

const link =
  'https://fh.filgolf.com/files/ele/C6_Audited_Financial_Statements_as_of_31_December_2020.pdf';

export default function C6() {
  return (
    <>
      <h5 className="is-size-5 mb-4">
        <strong>
          C.6. Audited Financial Statements as of 31 December 2020
        </strong>
      </h5>
      <a className="button  is-link" href={link} download>
        DOWNLOAD
      </a>
      <PDFViewer
        document={{
          url: link
        }}
        rotationAngle={0}
        css="customViewer"
      />
    </>
  );
}
