import { useEffect } from "react";

export default function AboutUs() {
  useEffect(() => {
    document.title = "Forest Hills Golf and Country Club | About us";
  }, []);
  return (
    <>
      <h5 className="is-size-5 mb-4">
        <strong>About us</strong>
      </h5>
      <p className="mb-4">
        <strong>Forest Hills Golf and Country Club</strong> is a world-class
        golf and leisure facility nestled in the rolling foothills the Sierra
        Madre Mountain Range, and easy drive from Manila through Marcos Highway
        all the way up to Barangay Inarawan.
      </p>
      <p className="mb-4">
        Designed by golf legends Jack Nicklaus and Arnold Palmer, Forest Hills
        features a 36-Hole championship golf course, a multi-level clubhouse,
        the famous Aqua Park and a breathtaking panoramic view of the Ortigas
        Skyline.
      </p>
      <p className="mb-4">
        Forest Hills' unique location gave the architect, Ruben Payumo, an
        opportunity to create an iconic design for the site. The terrain,
        rolling hills and the magnificient vistas offered by Forest Hills'
        location motivated the architect to depart from the usual box-type
        building design. Thus the concept of three interlocking circular masses
        with a conical roof emerged.
      </p>
      <table class="table is-bordered is-center">
        <thead>
          <tr>
            <th>Particulars</th>
            <th>Nicklaus</th>
            <th>Palmer</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Grass on Greens</td>
            <td>Tifdwarf</td>
            <td>Tifdwarf</td>
          </tr>
          <tr>
            <td>Grass on Fairways</td>
            <td>Tifway 419</td>
            <td>Tifway 419</td>
          </tr>
          <tr>
            <td>Total Yardage</td>
            <td>7,015</td>
            <td>6,186</td>
          </tr>
          <tr>
            <td>Date Completed</td>
            <td>April 1997</td>
            <td>
              Front 9 - March 2004
              <br />
              Back 9 - May 2012
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}
